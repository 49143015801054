import React, {Component} from "react"
import {graphql, navigate} from "gatsby"
// import {useMsal} from "@azure/msal-react";
import {withMsal, MsalContext} from "@azure/msal-react";
import Img from "gatsby-image"
import SEO from "components/seo"
import constants from "../constants"
import Actions from "../actions"
import Store from "../store"

class IndexPage extends Component {
  static contextType = MsalContext;
  constructor(props) {
    super(props)
    this.email = React.createRef()
    this.password = React.createRef()
    this.parent = React.createRef()
    this.state = {
      isConnecting: false
    }
  }

  componentDidMount() {
    setTimeout(() => {
      this.setState({isConnecting: false})
      navigate(`/lookbook/ss23`)
    }, 100)
    // Store.on(constants.LOGIN, this.onLogin)
    // Store.on(constants.SSOLOGIN, this.onLogin)
    // Store.isCustomer()
    //   .then(response => {
    //     if (response.status !== 200) throw new Error(response.status)
    //     return response.text()
    //   })
    //   .then(result => {
    //     const data = JSON.parse(result)
    //     if (data.id) navigate(`/home`)
    //   })
    //   .catch(() => {
    //     this.parent.current.classList.remove(`opacity-0`)
    //     this.parent.current.classList.add(`opacity-100`)
    //   })
  }
  componentWillUnmount() {
    // Store.off(constants.LOGIN, this.onLogin)
  }
  onLogin = (e) => {
    setTimeout(() => {
      this.setState({isConnecting: false})
      navigate(`/lookbook/ss23`)
      // navigate(`/home`)
    }, 1000)
  }
  submitHandler = (e) => {
    e.preventDefault()
    this.setState({isConnecting: true})
    const user = {
      username: this.email.current.value,
      password: this.password.current.value,
    }
    setTimeout(Actions.login, 0, user)
  }

  SSOHandler = async () => {
    this.setState({isConnecting: true})
    const msalInstance = this.context.instance;
    const {idToken} = await msalInstance.loginPopup()
    setTimeout(Actions.ssoLogin, 0, idToken)
  }

  render() {
    const mainImage = this.props.data.file.childImageSharp.fluid
    const hoverBtn = `opacity-50 hover:opacity-100`
    return (
      <div>
        <SEO
          title={`Connect to Ecco`}
          pathname={this.props.location.pathname}
        />

        <div ref={this.parent} className={`w-screen h-full lg:h-screen opacity-0 duration-300 ease-out bg-[#C8D94C]`}>
          <div className={`flex flex-row h-full bg-[#C8D94C]`}>
            <div className={`hidden lg:block bg-black w-connect-half h-full`}>
              <Img className={`w-full h-full`} style={{objectFit: 'cover'}} fluid={mainImage} />
            </div>
            <div className={`bg-[#C8D94C] flex-1 flex flex-col justify-top pt-20 lg:pt-0 lg:justify-center`}>
              <div className="mx-auto w-9/12 max-w-sm lg:w-96">
                <div>
                  <img
                    className={`w-full h-auto`}
                    src="logo_black.svg"
                    alt="Connect to Ecco"
                  />
                  <h2 className={`mt-8 lg:mt-12 text-center ${constants.attribution} text-sm leading-5 text-black`}>
                    {constants.TEXTS?.CONNECT_EXPERIENCE}
                  </h2>
                </div>
                <div className="mt-8 lg:mt-12">
                  <form onSubmit={this.submitHandler} noValidate className="space-y-6">
                    <div>
                      <div>
                        <input
                          ref={this.email}
                          required
                          type="text"
                          name="email"
                          id="connect_email"
                          className={constants.FORM_INPUT}
                          placeholder={constants.TEXTS?.YOUR_EMAIL}
                        ></input>
                      </div>
                    </div>
                    <div>
                      <div className="">
                        <input
                          autoComplete="current-password"
                          required
                          ref={this.password}
                          type="password"
                          name="password"
                          id="connect_password"
                          className={constants.FORM_INPUT}
                          placeholder={constants.TEXTS?.PASSWORD}
                        ></input>
                      </div>
                    </div>
                    <div className={`flex justify-between`} >
                      <button
                        type='button'
                        onClick={this.SSOHandler}
                        className={`${constants.ICON_BUTTON} h-16 mt-4 mr-8 relative text-white`}
                      >
                        <img
                          className={`w-auto h-half px-3 duration-100 ease-out opacity-100`}
                          src="/logo_white_single.svg"
                          alt="Submit Button"
                        />
                        {this.state.isConnecting === false && 'Sign In'}
                        {this.state.isConnecting &&
                          <div className={`w-14 h-14 ease-out duration-100 ${this.state.isConnecting ? `opacity-100 scale-100 ` : `opacity-0 scale-95`}`}>
                            <svg className={`animate-spin w-full h-full px-3 text-[#C8D94C]`} fill="none" viewBox="0 0 24 24">
                              <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                              <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                            </svg>
                          </div>}
                      </button>
                      <button
                        type="submit"
                        className={`${constants.ICON_BUTTON} w-20 h-16 mt-4 relative`}
                      >
                        <img
                          className={`w-full h-auto px-3 duration-100 ease-out ${this.state.isConnecting ? `opacity-0` : `opacity-100`}`}
                          src="/arrow_green.svg"
                          alt="Submit Button"
                        />
                        <div className={`absolute w-14 h-14 ease-out duration-100 ${this.state.isConnecting ? `opacity-100 scale-100 ` : `opacity-0 scale-95`}`}>
                          <svg className={`animate-spin w-full h-full px-3 text-[#C8D94C]`} fill="none" viewBox="0 0 24 24">
                            <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                            <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                          </svg>
                        </div>
                      </button>
                    </div>
                  </form>
                </div>
              </div>
              <div
                className={`${constants.caption} text-center text-black mx-auto w-11/12 mt-8`}
              >
                Didn't received a password?{" "}
                <a
                  className={constants.SIMPLE_LINK}
                  target={`_blank`}
                  href={constants.TEXTS?.EMAIL_HREF}
                >
                  Contact us
                </a>
                {/* for details <span className={`inline lg:hidden`}><br/><br/></span> <span className={`hidden lg:inline px-2`}>|</span> Lost your token?{" "}
                <a
                  className={constants.SIMPLE_LINK}
                  target={`_blank`}
                  href={`/`}
                >
                  Send it again
                </a>
                . */}
              </div>
            </div>
          </div>
          <footer className="static pt-20 lg:pt-0 lg:fixed z-10 bottom-0 w-full text-xs bg-[#C8D94C] lg:bg-transparent">
            <div className="mx-auto py-6 px-4 sm:px-6 md:flex md:items-center md:justify-between lg:px-8">
              <div className="flex justify-center space-x-10 md:order-2">
                <a href={constants.TEXTS?.CONTACT_HREF} className={hoverBtn}>
                  <span className="text-black">CONTACT US</span>
                </a>
                <a href={constants.TEXTS?.EMAIL_HREF} className={hoverBtn}>
                  <span className="text-black">E-mail</span>
                </a>
              </div>
              <div className={`hidden lg:block mt-8 md:mt-0 md:order-1 opacity-100`}>
                <div className="flex">
                  {/* <div className="mr-4 flex-shrink-0 self-center">
                    <img
                      className=""
                      src="/logo_black_single.svg"
                      alt="Ecco Copyright"
                    />
                  </div> */}
                  <div>
                    <p className="">&copy; ECCO 2022 All Rights Reserved</p>
                  </div>
                </div>
              </div>
            </div>
          </footer>
        </div >
      </div >
    )
  }
}

export const query = graphql`
  query {
    file(relativePath: { eq: "images/connect_page_2.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1200) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
  }
`

export default IndexPage
